import { CONST } from "lib/const";
import type { Route } from "nextjs-routes";
import { isBrowser } from "./ssr";

const gaId = CONST.GA_ID;
const gtmId = CONST.GTM_ID;

// https://morganfeeney.com/how-to/integrate-google-tag-manager-with-next-js
export const pushEvent = (stateLogin: string, currUser: any, rest: any) => {
  let dataPush = {
    user_id: rest.user_id ?? null,
    referral_code: rest?.referral_code ?? null,
    event: rest?.event ?? "user_identity",
    ...rest,
  };

  if (stateLogin == "LOGIN") {
    dataPush.user_id = currUser?.id;
    dataPush.referral_code =
      currUser?.referral !== "" ? currUser?.referral : null;
  } else {
    dataPush.referral_code = null;
    dataPush.user_id = null;
    dataPush.event = "user_identity";
  }

  if (typeof window !== "undefined") {
    console.log("push to GA ~~", {
      ...dataPush,
      ...rest,
    });

    // @ts-ignore
    window.dataLayer.push({
      ...dataPush,
      ...rest,
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: Route): void => {
  //@ts-ignore
  window.gtag("config", gaId, {
    page_path: url,
  });
};

export const pushDataLayer = ({ ...rest }) => {
  if (isBrowser()) {
    //@ts-ignore
    return window.dataLayer.push({
      ...rest,
    });
  }
};
