import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactNode,
} from "react";
import FieldError from "./FieldError";

export type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  name?: string;
  label?: string;
  validator?: any;
  children?: ReactNode;
  className?: string;
  info?: string;
  islast?: boolean;
  isFull?: boolean;
  additionalInfo?: string;
  messageError?: string;
};

const FormField: React.FC<Props> = ({
  name,
  label,
  validator,
  children,
  className,
  info,
  islast,
  isFull,
  required,
  additionalInfo,
  messageError
}) => {
  return (
    <div
      className={`${!islast ? "mb-24" : ""} ${className || ""} ${
        isFull ? "w-full" : ""
      }`}
    >
      {label && (
        <div className={additionalInfo ? "mb-4" : "mb-8"}>
          <label className="text-b1 text-neutral-80 font-medium mb-0 block flex gap-4">
            {label}{" "}
            {required && <div className="text-red-50 font-medium">*</div>}
          </label>
        </div>
      )}
      {additionalInfo && (
        <div className="mb-8">
          <span className="font-light text-b2">{additionalInfo}</span>
        </div>
      )}
      {children}
      {name && validator && <FieldError name={name} validator={validator} />}
      {messageError && <p className="mt-8 text-12 text-red-70">{messageError}</p>}
      {info && <p className="mt-8 mb-0 text-12 text-neutral-50">{info}</p>}
    </div>
  );
};

export default FormField;
