import TranslationID from "data/locales/id/business.json";
import React, { useState } from "react";
import { usePopupStoreFormSales } from "../Home/popupStore";
import { IconCheckCircle } from "components/Icon/IconCheckCircle";
import { Image } from "components/Image/Image";
import { BestCompanies } from "modules/Business/HomeInstitutions";
import FormFieldText from "components/Form/FieldText";
import { usePostContactBusiness } from "modules/Business/businessApi";
import { useToastStore } from "components/Toast/toastStore";
import Button from "components/Button";
import { pushDataLayer } from "lib/gtag";

export const HomePopUp: React.FC<{ eventId?: string; forHris?: boolean }> = ({
  eventId = "popup-sales",
  forHris = false,
}) => {
  const textDocs = TranslationID["PopupFormSales"];

  const { closePopupFormSales, currentCtaEvent } = usePopupStoreFormSales(
    (state) => ({
      closePopupFormSales: state.closePopupFormSales,
      currentCtaEvent: state.currentCtaEvent,
    })
  );
  const [contact, setcontact] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const showToast = useToastStore((state) => state.showToast);
  const usePostApi = usePostContactBusiness();

  let finalEvent = eventId;
  if (currentCtaEvent != "") finalEvent = currentCtaEvent;
  const submit = () => {
    setIsLoading(true);

    if (!contact) {
      showToast({
        type: "danger",
        message: "Pastikan kontak anda sudah diisi",
      });
      setIsLoading(false);
      return;
    }
    const urlOrigin = window.location.href + "?event=" + finalEvent;
    usePostApi.doFetch(
      {
        contact,
        url_origin: urlOrigin,
      },
      {
        onSuccess(res) {
          pushDataLayer({ event: "success-leads" });

          closePopupFormSales();

          // reset form field
          setcontact("");

          //@ts-ignore
          if (res.message === "success") {
            showToast({
              type: "success",
              message: "Kontak anda berhasil terkirim",
            });
            setIsLoading(false);
          }
        },
        onError(err) {
          showToast({
            type: "danger",
            message: err.data?.message ?? err.message ?? "Error",
          });
          setIsLoading(false);
        },
      }
    );
  };
  return (
    <div className="items-center grid grid-cols-2 gap-x-[42px] lg-max:grid-cols-1 2xl-max:gap-y-[42px]">
      {/* desktop: left - mobile: bottom */}
      <div className="lg-max:order-last flex flex-col gap-20 text-left">
        <div
          dangerouslySetInnerHTML={{
            __html: textDocs.title,
          }}
          className="font-black text-h2 lg-max:text-h3"
        ></div>
        <div className="font-medium text-b1 lg-max:text-b1 text-">
          {textDocs.description}
        </div>
        {textDocs.benefits.length > 0 ? (
          <div className="grid">
            {textDocs.benefits.map((elm) => (
              <div className="flex justify-start items-center gap-8" key={elm}>
                <div className="w-24">
                  <IconCheckCircle color="#551FC1" size={24} />
                </div>
                <div
                  className="text-left text-neutral-80 font-light"
                  dangerouslySetInnerHTML={{
                    __html: elm,
                  }}
                ></div>
              </div>
            ))}
          </div>
        ) : null}
        <div className="font-medium text-b1 lg-max:text-b1 text-">
          {textDocs.institutionTitle}
        </div>
        {BestCompanies && BestCompanies.length > 0 && (
          <div className="grid gap-20 grid-cols-6 lg-max:grid-cols-4 hg-max:!grid-cols-3">
            {BestCompanies.map((institution) => (
              <div
                key={institution.id + Math.random()}
                className="w-56 h-56 m-auto sm-max:w-32 sm-max:h-32"
              >
                <Image
                  width={56}
                  height={56}
                  src={institution.image_url}
                  alt={institution.name}
                  className={`w-full h-full`}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {/* desktop: right - mobile: top */}
      <div className="relative justify-center items-center">
        <div className="!bg-white flex flex-col gap-8 shadow-chip_total rounded-16 bg-chip-pattern p-32">
          <div className="font-black text-h3 lg-max:text-h5">
            {textDocs.contactSales}
          </div>
          <div className="font-light text-b1 sm-max:text-b1 py-4">
            {textDocs.contactSalesDesc}
          </div>
          <div className="font-medium text-b2 text-neutral-60">
            {forHris
              ? textDocs.fieldContactHrisInput
              : textDocs.fieldContactInput}
          </div>
          <FormFieldText
            name="contact"
            islast={true}
            classBase="h-full sm-max:w-full !mb-16"
            inputStyle="!rounded-8 h-40 sm-max:!w-full"
            placeholder={
              forHris
                ? textDocs.fieldContactHrisPlaceholder
                : textDocs.fieldContactPlaceholder
            }
            value={contact}
            onChange={(e) => setcontact(e.target.value)}
          />
          <Button
            className={`flex justify-center items-center gap-8 !rounded-8 !text-b1`}
            onClick={() => {
              pushDataLayer({ event: finalEvent });
              submit();
            }}
            isFull
            state={isLoading ? "loading" : "active"}
          >
            {textDocs.fieldSubmit}
          </Button>
        </div>
      </div>
    </div>
  );
};
