import React, { ReactNode } from "react";

export type Props = {
  name: string;
  validator: any;
};

const FormFieldError: React.FC<Props> = ({ name, validator }) => {
  if (validator) {
    let message = "Error";
    let isError = false;

    const { hook } = validator;
    const {
      formState: { errors },
    } = hook;

    if (errors && typeof errors[name] !== "undefined") {
      isError = true;
      if (errors[name]?.message) {
        message = errors[name]?.message;
      }
    }

    if (isError) {
      return <p className="mt-8 text-12 text-red-70">{message}</p>;
    }
    return null;
  }
  return null;
};

export default FormFieldError;
