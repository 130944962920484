import { ReactNode, ReactElement } from "react";
import { Controller } from "react-hook-form";

export type Props = {
  name: string;
  validator: any;
  classBase?: string;
  onRender(field: any): ReactElement;
  defaultValue?: any;
  afterInput?: ReactNode;
  beforeInput?: ReactNode;
  iconBeforeInput?: ReactNode;
};

export const style =
  "w-full px-8 h-40 bg-field_input text-b1 text-neutral-80 border border-neutral40_to_dark20 rounded-4 disabled:bg-field_input_disable placeholder-input_placeholder placeholder:font-light focus-visible:outline-2 focus-visible:outline-blue-50";

const FormInputBase: React.FC<Props> = ({
  name,
  validator,
  classBase,
  onRender,
  defaultValue = "",
  afterInput,
  beforeInput,
  iconBeforeInput,
}) => {
  const renderInput = () => {
    const { hook, rules } = validator;
    const { control } = hook;

    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field }) => onRender(field)}
      />
    );
  };

  return (
    <div className={`relative ${classBase || ""}`}>
      {beforeInput}
      {iconBeforeInput && (
        <div className={`absolute left-12 z-10 flex items-center h-full`}>{iconBeforeInput}</div>
      )}
      {validator ? renderInput() : <>{onRender({})}</>}
      {afterInput}
    </div>
  );
};

export default FormInputBase;
